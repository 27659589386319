import * as React from "react";
import {LastWeekReturns} from "./LastWeekReturns";
import {ReturnReasonsChart} from "./ReturnReasonsChart";
import {Container, Grid} from "@mui/material";
import {ReturnsTypeChart} from "./ReturnsTypeChart";
import {ReturnsStateChart} from "./ReturnsStateChart";

export const Dashboard = () => {
    return <>
        <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            >
            <Grid item xs={12}>
                <LastWeekReturns />
            </Grid>
            <Grid item xs={12} lg={6}>
                <ReturnReasonsChart />
            </Grid>
            <Grid item xs={12} lg={6}>
                <ReturnsTypeChart />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ReturnsStateChart />
            </Grid>
        </Grid>
    </>
}