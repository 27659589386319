import {Audience, AuthHttpClient} from "@ucirq/protocol/lib/protocol/auth";
import {authClient} from "./HttpClients";

export interface LoginDetailsI {
    username: string
    password: string
}

export interface LoginDetailsRequestI {
    login: string
    password: string
}

export interface TokenI {
    "token": string,
    "expiry": number,
    "createdTime": number
}

export interface JwtSigningPublicKeyI extends TokenI {
    "publicKey": string,
}

interface AuthManagerI {
    authApiUrl: string
    authClient: AuthHttpClient
    login: (loginDetails: LoginDetailsRequestI) => Promise<Jwt | Error>,
}

export interface ErrorResponse {
    status: number
}

type Jwt = string

export const AuthManager: AuthManagerI = {
    authApiUrl: process.env.REACT_APP_AUTH_ENDPOINT as string,
    authClient: authClient,
    async login(loginDetails: LoginDetailsRequestI): Promise<Jwt | Error> {
        const response = await this.authClient.logIn(Audience.MerchantAdmin, loginDetails.login, loginDetails.password)
        return response as Jwt
    },
}
