import {useNotify} from "react-admin";
import {getStatistics} from "../api/StatisticsProvider";
import {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Text, ResponsiveContainer} from 'recharts';
import * as React from "react";
import {StatisticsPeriod} from "@ucirq/protocol/lib/protocol/returns";
import {Box, Card, TextField, Typography} from "@mui/material";
import CardContent from "@material-ui/core/CardContent";
import {DateRangePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from '@mui/lab/AdapterDateFns';
import {RangeInput} from "@mui/lab/DateRangePicker/RangeTypes";
import {getDate} from "../tools";
import {cartContentStyle} from "../styles";

export const ReturnReasonsChart = () => {

    const notify = useNotify();
    const [value, setValue] = useState<RangeInput<Date>>([getDate(new Date(), 7), getDate(new Date())]);
    const {data, error, run} = useRequest(getStatistics, {
        manual: true,
    })

    useEffect(() => {
        (async () => {
            await run({
                start: value[0],
                end: value[1]
            } as StatisticsPeriod)
        })()
    }, [run, value])
    if (error) {
        notify(error.message, 'error')
    }
    return <Card>
        <CardContent style={cartContentStyle.base}>
            <Typography gutterBottom variant="h5" component="div" align={"center"}>
                Returns Reasons
            </Typography>
                <BarChart
                    width={500}
                    height={300}
                    data={data?.returnReasons}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="reason"/>
                    <YAxis/>
                    <Tooltip/>
                    <Text/>
                    <Bar dataKey="quantity" fill="#82ca9d"/>
                </BarChart>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DateRangePicker
                    startText="From"
                    endText="To"
                    value={value}
                    onChange={(newValue) => {
                        console.log(newValue)
                        const [start, end] = newValue
                        if (start && end) {
                            setValue([getDate(start), getDate(end)]);
                        }
                    }}
                    renderInput={(startProps, endProps) => (
                        <>
                            <TextField {...startProps} />
                            <Box sx={{mx: 2}}> to </Box>
                            <TextField {...endProps} />
                        </>
                    )}
                />
            </LocalizationProvider>
        </CardContent>
    </Card>
}