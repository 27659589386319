import * as React from "react";

import {Dashboard} from "./components/Dashboard";
import authProvider from "./api/AuthProvider";
import dataProvider from "./api/DataProvider";
import {Admin, DataProvider, Resource} from "react-admin";
import {ReturnShow} from "./components/ReturnShow";
import {ReturnsList} from "./components/ReturnsList";

const App = () => (
    <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider as unknown as DataProvider} disableTelemetry>
        <Resource name="returns" show={ReturnShow} list={ReturnsList} />
    </Admin>
);


export default App;
