import * as React from "react";
import {FC} from "react";
import {AutocompleteInput, Datagrid, List, ShowButton, TextField, TextInput} from "react-admin";
import {ReturnState} from "@ucirq/protocol/lib/protocol/returns";
const returnsFilter = [
    <TextInput source="order_number" label="Order ID"/>,
    <TextInput source="customer_email" label="Email"/>,
    <AutocompleteInput
        source="state"
        resource="state"
        label="State"
        choices={
            Object.keys(ReturnState).map(state => {
                    return {id: state, name: state}
                }
            )
        }
    />
];
export const ReturnsList: FC = (props) => {
    return <List filters={returnsFilter} {...props} hasShow={true}>
        <Datagrid>
            <TextField source="id" resource="id" sortBy="return_id" label={"Return ID"}/>
            <TextField source="order.orderNumber" sortBy="order_number" label={"Order ID"}/>
            <TextField source="order.merchantName" sortable={false} label={"Merchant Name"}/>
            <TextField source="customer.emailAddress" sortBy="customer_email" label={"Customer Email"}/>
            <TextField source="activity.[0].state" sortBy="state" label={"State"}/>
            <ShowButton />
        </Datagrid>
    </List>
};