import React from "react";

export const cartContentStyle = {
    base: {
        "display": "flex",
        "align-items": 'center',
        "flex-direction": "column",
        "justify-content": "center",
        "gap": "10px"
    } as React.CSSProperties
}
