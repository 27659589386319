import {useNotify} from "react-admin";
import {getStatistics} from "../api/StatisticsProvider";
import {FC, useEffect} from "react";
import {useRequest} from "ahooks";
import * as React from "react";
import {StatisticsPeriod} from "@ucirq/protocol/lib/protocol/returns";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {getDate} from "../tools";
import {errorHandler} from "../api/ErrorHandler";

export const LastWeekReturns: FC = () => {
    const notify = useNotify();
    const {data, error, run} = useRequest(getStatistics, {
        manual: true,
    })
    useEffect(() => {
        (async () => {
            await run({
                start: getDate(new Date(), 7) as unknown as Date,
                end: getDate(new Date()) as unknown as Date
            } as StatisticsPeriod)
        })()
    }, [run])
    if (error) {
        const {message,callback} = errorHandler(error)
        notify(message, 'error')
        callback  && callback()
    }
    return <Card>
        <CardContent>
            <p>Returns for the last week: <b>{data ? data.total : ""}</b></p>
        </CardContent>
    </Card>
}