import {DataProvider, GetOneParams} from "react-admin";
import jsonServerProvider from "ra-data-simple-rest";
import {
    Filter, GetMerchantListResult,
    Range as SearchRange,
    SearchCriteria,
    Sort
} from "@ucirq/protocol/lib/protocol/returns";
import {returnsClient} from "./HttpClients";
import {errorHandler} from "./ErrorHandler";

const dataProvider: DataProvider = jsonServerProvider("")
// @ts-ignore
dataProvider.getList = (resource: string, params: GetListParams) => {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    const filterField = Object.keys(params.filter)[0], value = Object.values(params.filter)[0];
    // @ts-ignore
    return returnsClient.getMerchantReturnsList("", SearchCriteria.fromValue(
        {
            range: SearchRange.fromValue({page: page, perPage: perPage}),
            sort: Sort.fromValue({field: field, order: order}),
            filter: filterField ? Filter.fromValue({field: filterField, value: value}) : null
        }
    )).then((r: GetMerchantListResult) => {
        const contentRange = r.contentRange
        const total = contentRange.last
        return {
            data: r.result,
            total: total,
        }
    }).catch(e => {
        return Promise.reject(errorHandler(e).message)
    });
}
// @ts-ignore
dataProvider.getOne = (resource: string, params: GetOneParams) => {
    // @ts-ignore
    return returnsClient.returnById(params.id as ReturnId).then((r: ReturnFetch) => {
        return {
            data: r
        }
    });
}

export default dataProvider