import {useNotify} from "react-admin";
import {getStatistics} from "../api/StatisticsProvider";
import {FC, useEffect, useState} from "react";
import {useRequest} from "ahooks";
import * as React from "react";
import {Box, Card, CardContent, Grid, TextField, Typography} from "@mui/material";
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Text, ResponsiveContainer} from 'recharts';
import DateAdapter from "@mui/lab/AdapterDateFns";
import {DateRangePicker, LocalizationProvider} from "@mui/lab";
import {RangeInput} from "@mui/lab/DateRangePicker/RangeTypes";
import {StatisticsPeriod} from "@ucirq/protocol/lib/protocol/returns";
import {getDate} from "../tools";
import {cartContentStyle} from "../styles";

export const ReturnsStateChart: FC = () => {
    const notify = useNotify();
    const {data, error, run} = useRequest(
        getStatistics, {
            manual: true,
        }
    )
    const [value, setValue] = useState<RangeInput<Date>>([getDate(new Date(), 7), getDate(new Date())]);

    useEffect(() => {
        run({
            start: value[0],
            end: value[1]
        } as StatisticsPeriod)
    }, [run, value])
    if (error) {
        notify(error.message, 'error')
    }
    return <Card>
        <CardContent style={cartContentStyle.base}>
            <Typography gutterBottom variant="h5" component="div" align={"center"}>
                Returns State
            </Typography>
            <ResponsiveContainer width="100%" height="80%" minHeight={100} minWidth={500}>
                <BarChart
                    data={data?.returnStates}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="state"/>
                    <YAxis/>
                    <Tooltip/>
                    <Text/>
                    <Bar dataKey="quantity" fill="#82ca9d"/>
                </BarChart>
            </ResponsiveContainer>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DateRangePicker
                    startText="From"
                    endText="To"
                    value={value}
                    onChange={(newValue) => {
                        const [start, end] = newValue
                        if (start && end) {
                            const newValue = [getDate(start), getDate(end)]
                            setValue(newValue as RangeInput<Date>);
                        }
                    }}
                    renderInput={(startProps, endProps) => (
                        <>
                            <TextField {...startProps} />
                            <Box sx={{mx: 2}}> to </Box>
                            <TextField {...endProps} />
                        </>
                    )}
                />
            </LocalizationProvider>
        </CardContent>
    </Card>
}