import * as React from "react";
import {FC} from "react";
import {Show, SimpleShowLayout, TextField, useShowController} from 'react-admin';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import {TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography} from "@mui/material";

export interface Article {
    name: string;
    barcode: string | null;
    productId: string;
    variantId: string | null;
}

export interface RefundItem {
    id: string;
    count: number;
    reason: string;
    article: Article;
}

export interface RecordI {
    refunds: Array<RefundItem>
}

// @ts-ignore
export const ReturnShow: FC<ShowProps> = (props) => {
    const record = useShowController(props).record as unknown as RecordI;
    return <>
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" label={"Return ID"}/>
                <TextField source="activity.[0].state" label={"State"}>
                </TextField>
                <TextField source="activity.[0].state" label={"State"}/>
            </SimpleShowLayout>
        </Show>
        {
            record.refunds.length && <Show {...props}>
                <TableContainer component={Paper}>
                    <Toolbar>
                        <Typography
                            sx={{flex: '1 1 100%'}}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            {"Refunds"}
                        </Typography>
                    </Toolbar>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">{"ID"}</TableCell>
                                <TableCell align="center">{"Count"}</TableCell>
                                <TableCell align="center">{"Reason"}</TableCell>
                                <TableCell align="center">{"Product ID"}</TableCell>
                                <TableCell align="center">{"Product Name"}</TableCell>
                                <TableCell align="center">{"Barcode"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                record.refunds.map(refund => (
                                    <TableRow key={refund.id}>
                                        <TableCell align="left">{refund.id}</TableCell>
                                        <TableCell align="center">{refund.count}</TableCell>
                                        <TableCell align="center">{refund.reason}</TableCell>
                                        <TableCell align="center">{refund.article.productId}</TableCell>
                                        <TableCell align="center">{refund.article.name}</TableCell>
                                        <TableCell align="center">{refund.article.barcode}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Show>
        }
    </>
}
