import {AuthManager, LoginDetailsI, LoginDetailsRequestI} from "./AuthManager"

const AuthProvider = {
    login: (loginDetails: LoginDetailsI): Promise<boolean> => {
        try {
            const loginData: LoginDetailsRequestI = {
                login: loginDetails.username,
                password: loginDetails.password
            }
            return AuthManager.login(loginData).then((loginResponse) => {
                    // @ts-ignore
                    localStorage.setItem("jwt", loginResponse)
                    return Promise.resolve(true)
                }
            )

        } catch (e) {
            return Promise.reject(false);
        }
    },
    logout: () => {
        localStorage.removeItem('jwt');
        return Promise.resolve();
    },
    checkError: (status: number) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('jwt');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('jwt')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
}


export default AuthProvider