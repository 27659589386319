import {InvalidToken, TokenExpired} from "@ucirq/protocol/lib/protocol/auth";
import AuthProvider from "./AuthProvider";

export interface ErrorHandleResult {
    message: string,
    callback?: () => void
}
const logOut = () => setTimeout(() => {
    AuthProvider.logout();
    window.location.reload()
}, 3000)
export const errorHandler = (error: any): ErrorHandleResult => {
    switch (error.error) {
        case "TokenExpired":
            return {message: "Your session is expired. Please log in again", callback: logOut}
        case "InvalidToken":
            return {message: "Invalid token. Please log in again", callback: logOut}
        default:
            return {message: error.message}
    }
}