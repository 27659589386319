import {ReturnsHttpClient} from "@ucirq/protocol/lib/protocol/returns";
import {RPCHTTPClient} from "@ucirq/protocol/lib/protocol/Client";
import ky from "ky";
import {AuthHttpClient} from "@ucirq/protocol/lib/protocol/auth";

export const returnsClient = new ReturnsHttpClient(
    new RPCHTTPClient(
        ky,
        process.env.REACT_APP_RPC_ENDPOINT as string,
        async () => localStorage.getItem("jwt")
    ))

export const authClient = new AuthHttpClient(
    new RPCHTTPClient(
        ky,
        process.env.REACT_APP_RPC_ENDPOINT as string,
        async () => null
    ))
